import axios from "axios";
import { app } from '@/main.js'
axios.interceptors.request.use(function(config) {
  // Do something before request is sent
  app.runLoader();
  return config;
}, function(error) {
  // Do something with request error
  console.log('Error');
  return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
  // Do something with response data
  app.hideLoader();
  return response;
}, function(error) {
  // Do something with response error
  return Promise.reject(error);
});


export default {
  
  get(url) {
    return axios.get(`${app.API_BASE_URL}${url}`).then((r)=>{
        return r['data'];
    });
  },
  post(url,payload) {
    return axios.post(`${app.API_BASE_URL}${url}`, payload).then((r)=>{
      return r['data'];
    });
  }
};