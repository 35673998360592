<template>
  <div>
    <v-toolbar
      :color="primaryColor"
      dark
      >
      <v-btn  icon @click="$router.go(-1)">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
      <v-toolbar-title class="flex text-center">{{ $session.get("company_name")  }}</v-toolbar-title>
      <v-app-bar-nav-icon style="visibility:hidden"></v-app-bar-nav-icon>
      
    </v-toolbar>
    <v-divider></v-divider>
    <v-card :color="primaryColor" class="white--text pa-1 rounded-0 subtitle-2" align="center">
          Shift Detail
       </v-card>
       <v-container class="pa-0">
        <StreamBarcodeReader
        willReadFrequently ="true"
          @decode="onDecode"
          @loaded="onLoaded"
      ></StreamBarcodeReader>
          
       </v-container>
  </div>

</template>
  
  <script>
  import { StreamBarcodeReader } from "vue-barcode-reader";
  
  export default {
    name: 'ShiftPatrol',
    components: {
      StreamBarcodeReader
    },
     data() {
    return {
    }
  },
  created: function() {
    },
    methods:{
      onLoaded() {
        console.log(`Ready to start scanning barcodes`)
      },
      onDecode(qr){
        var officer_id = this.$session.get("officer_id");
        this.$getLocation({
          enableHighAccuracy: true, //defaults to false
      })
        .then(coordinates => {
          this.$root.latitude = coordinates.lat;
          this.$root.longitude = coordinates.lng;
          var params=new FormData();
          params.append("officer_id",officer_id);
          params.append("long",this.$root.latitude);
          params.append("lat",this.$root.longitude);
          params.append("data",qr);
          this.$api.post("qr",params).then((response)=>{
                  var data = response['data'];
                  if(data.qrcode == 0)
                    this.$root.vtoast.show({message: data['message'],color:'red',icon:'mdi-close'})
                  else{
                    this.$root.vtoast.show({message: data['message']})
                    this.$router.push('/')
                  }
          });
        });
      },
      
    }

}
  </script>
  