<template>
  <v-container class="">
    <v-col cols="12" sm="8" md="4" align="center" class="justify-space-between">
        <v-img
          style="margin-top:100px !important; "
          alt="Guardtrack"
          class="shrink ma-8"
          contain
          :src="LOGO_URL"
          transition="scale-transition"
          width="150"
        />
        
        <v-form ref="form" v-model="valid" lazy-validation style="margin-top:70px;">
        <v-text-field
          v-model="pin"
          :counter="6"
          :rules="pinRules"
          label="Enter PIN e.g 000000"
          required
          outlined
          type="number"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :counter="20"
          :rules="passwordRules"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          label="Password"
          required
          outlined
        ></v-text-field>
        <v-btn :disabled="!valid" :color="primaryColor" class="white--text" @click="submit" block>Login</v-btn>
      </v-form>

      </v-col>
    
</v-container>
</template>

<script>

export default {
  data() {
    return {
      valid: true,
      pin: "",
      password: "",
      remember: false,
      showPassword: false,
      pinRules: [
        v => !!v || "PIN is required",
        v => v.length >= 5 || "PIN must be at least 5 characters"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => v.length >= 6 || "Password must be at least 6 characters"
      ]
    }
  },
  created:function(){
    if(this.$session.exists() && this.$session.get("loggedIn") == true || this.$session.get("officer_id") != undefined || this.$session.get("officer_id") > 1) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    submit() {
      //var self = this;
      //this.runLoader();
      if (this.$refs.form.validate()) {
          var params=new FormData();
        params.append("email",this.pin);
        params.append("password",this.password);
        this.$api.post("authenticateOfficer",params).then((response)=>{
                var data = response['data'];
                if(data['authenticated'] == 1){
                  this.$session.start()
                  this.$session.set('loggedIn', true)
                  this.$session.set('officer_id', data['officer_id'])
                  this.$session.set('officer_name', data['officer_name'])
                  this.$session.set('company_name', data['company_name'])
                  this.$session.set('company_id', data['company_id'])
                  this.$router.push('/dashboard')
                  this.$root.vtoast.show({message: "Logged in"})
                }
                else
                {
                  this.$root.vtoast.show({message: data['message'],color:'red',icon:'mdi-close'})
                }
            });
      }
    }
  }
}
</script>
