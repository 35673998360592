import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from "./store/store.js";
import 'ag-grid-enterprise';
import Api from "./Helpers/api.js"
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import VueAlertify from 'vue-alertify';
import VueRouter from 'vue-router';
import VueSession from 'vue-session'
import VueGeolocation from 'vue-browser-geolocation';
import EasyRefresh from 'vue-easyrefresh'
Vue.config.productionTip = false
Vue.prototype.$api = Api
var buildCompany = "surrey";
var API_BASE_URL = "https://www.guardtrack.co.uk/Webservices/index/";
var LOGO_URL = "https://www.guardtrack.co.uk/assets/guardtracklogo.png";
var TITLE = "Guardtrack Staff";

var baseUrl = "http://staff.guardtrack.co.uk/";
var primaryColor="#374767";
var secondaryColor="#2E3B54";
var dividerColor='#283858';

if(buildCompany == "surrey")
{
  API_BASE_URL = "https://www.thesurreysecurity.com/Webservices/index/";
  LOGO_URL = "https://www.thesurreysecurity.com/assets/guardtracklogo.png";
  TITLE = "The Surrey Security Staff";

  baseUrl = "http://staffapp.thesurreysecurity.com/";
  primaryColor="#2E3192";
  secondaryColor="#A8A4CE";
}

Vue.mixin({
  data: function() {
    return {
      API_BASE_URL:API_BASE_URL,
      LOGO_URL:LOGO_URL,
      TITLE:TITLE,
      baseUrl:baseUrl,
      primaryColor:primaryColor,
     secondaryColor:secondaryColor,
      dividerColor:dividerColor,
      dialogActionButtonColor:'#374767',
      siteTypes:[],
      officers:[],
      contractors:[]
    }
  },
  methods: {
    runLoader(){
      this.$store.commit('show');
    },
    hideLoader(){
      this.$store.commit('hide');
    },
    capitalizeFirstLetter(str) {
      if(str == "" || str == "null" || str == null)
        return "";
      var firstChar = str.slice(0, 1).toUpperCase();
      return firstChar + str.slice(1);
    },
    currencyFormatter(str) {
      return '\xA3' + this.getNum(str).toFixed(2);
    },
    getNum(str) {
      return /[-+]?[0-9]*\.?[0-9]+/.test(str)?parseFloat(str):0;
    },
    dateFormatter(value) {
      var date = moment(value).format('DD-MM-YYYY');
      if(date == 'Invalid date')
        return "";
      else return date;  
    },
    dateFormatterNew(value) {
      var date = moment(value).format('dddd, Do MMMM');
      if(date == 'Invalid date')
        return "";
      else return date;  
    },
    todayDate(){
      return moment().format('YYYY-MM-DD');
    },
    getDateByAddingMonth(month){
      return moment().add(month,'months').format('YYYY-MM-DD');
    },
    restrictedArea(){
      if(!this.$session.exists() || this.$session.get("loggedIn") == false || this.$session.get("officer_id") == undefined || this.$session.get("officer_id") < 1) {
        this.$router.push('/')
        return false;
    }
    else
    return true;
  },
  logout(){
    this.$session.destroy();
    this.$router.push('/')
    this.$root.vtoast.show({message: "Logged out"})

  },

  }
});

Vue.use(EasyRefresh)
Vue.use(VueRouter);
Vue.use(VueAlertify);
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(require('vue-moment'));
Vue.use(VueLoading);
Vue.use(VueSession)
Vue.use(VueGeolocation);


import StaffLogin from './components/staffApp/StaffLogin.vue';
import StaffDashboard from './components/staffApp/StaffDashboard.vue';
import ShiftDetail from './components/staffApp/ShiftDetail.vue'
import Patorl from './components/staffApp/Patrol.vue'
const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: StaffLogin },
    { path: '/dashboard', name:'dashboard', component: StaffDashboard },
    { path: '/detail', name:'detail', component: ShiftDetail },
    { path: '/patrol', name:'patrol', component: Patorl },
    { path: '*', component: StaffDashboard }
    
  ]
});




export const app = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
