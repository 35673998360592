import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
  showLoader: false
}
export default new Vuex.Store({
  state,
  mutations: {
    show(state){
        state.showLoader = true
    },
    hide(state){
        state.showLoader = false
    }
},

})