
<template>
  <v-app style="">
    <v-main>
      <vue-loading :active="showLoader" :color="primaryColor"></vue-loading>
      <location-manager v-if="locationStatus != 'granted'"></location-manager>
      <camera-manager v-else-if="cameraStatus != 'granted'"></camera-manager>
      <router-view class="view" v-else></router-view>
      <vue-toast ref="vtoast"/>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import VueLoading from 'vue-loading-overlay';
import VueToast from './components/VueToast'
import LocationManager from './components/helper/LocationManager'
import CameraManager from './components/helper/CameraManager'



const LocationHelper = {
  getLocation() {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then(permission => {
          if (permission.state === 'granted') {
            navigator.geolocation.getCurrentPosition(position => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              });
            }, reject);
          } else {
            reject(new Error("Geolocation permission is not granted"));
          }
        });
      } else {
        reject(new Error("Geolocation is not available"));
      }
    });
  }
};
export default {
  name: 'App',
  computed:{
    ...mapState({
      showLoader:state => state.showLoader
    }),
  },
  mounted() {
    document.title = this.TITLE
    this.$root.vtoast = this.$refs.vtoast
    LocationHelper.getLocation().then(location => {
    // do something with location
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }).catch(error => {
    // handle error
    console.log(error);
  });
  this.checkCameraStatus();
  this.checkLocationStatus();
},
methods:{
  checkCameraStatus:function(){
    var ref= this;
    navigator.permissions.query({ name: "camera" }).then(res => {
            this.cameraStatus = res.state;
            
            if(ref.timer == null && res.state == 'granted')
            {
              ref.timer = setInterval(function() {
                ref.checkCameraStatus();
              }, 60 * 1000);
            }
            else if(ref.timer == null && res.state != 'granted')
            {
              ref.timer = setInterval(function() {
                ref.checkCameraStatus();
              }, 5 * 1000);
            }
            else if(ref.timer != null && res.state == 'granted')
            {
              clearInterval(ref.timer);
              ref.timer = setInterval(function() {
                ref.checkCameraStatus();
              }, 60 * 1000);
            }
        });
  },
  checkLocationStatus:function(){
    var ref= this;
    navigator.permissions.query({ name: "geolocation" }).then(res => {
            this.locationStatus = res.state;
            
            if(ref.locationTimer == null && res.state == 'granted')
            {
              ref.locationTimer = setInterval(function() {
                ref.checkLocationStatus();
              }, 60 * 1000);
            }
            else if(ref.locationTimer == null && res.state != 'granted')
            {
              ref.locationTimer = setInterval(function() {
                ref.checkLocationStatus();
              }, 5 * 1000);
            }
            else if(ref.locationTimer != null && res.state == 'granted')
            {
              clearInterval(ref.locationTimer);
              ref.locationTimer = setInterval(function() {
                ref.checkLocationStatus();
              }, 60 * 1000);
            }
        });
  }
},
  components: {
    VueLoading,
    VueToast,
    LocationManager,
    CameraManager
  },

  data: () => ({
    latitude:null,
    longitude:null,
    timer:null,
    cameraStatus:"",
    locationStatus:"",
    locationTimer:null,
    //
  }),
};
</script>
