<template>
      <v-card elevation="0" class="full-height justify-center text-center"  height="100%">
        <v-card-title class="justify-center" >Location Access Required</v-card-title>
        <v-spacer></v-spacer>
        <v-icon size="64">mdi-map-marker</v-icon>
        <v-card-text class="justify-center">
          This application needs access to your location to function properly.
          <br>
          <span v-if="permissionDenied" class="red--text mt-8">Loction permission has been denied. Please enable location permission in your browser settings.</span>
          <br>
          <v-spacer></v-spacer>
         <span  class="green--text justify-center mt-8">Please reload page if you have already granted location permission.</span>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-center">
          <v-btn :color="primaryColor" class="white--text mt-8" @click="requestLocationAccess" block>Allow Location Access</v-btn>

        </v-card-actions>
      </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        permissionDenied: false,
      };
    },
    methods: {
      async requestLocationAccess() {
        try {
            if ("geolocation" in navigator) {
                // check if geolocation is available
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted') {
                    // geolocation permission already granted
                    this.locationPermissionGranted = true;
                    this.obtainLocation();
                } else if (result.state === 'prompt') {
                    // geolocation permission not yet granted, request it
                    navigator.geolocation.getCurrentPosition(this.obtainLocation, this.handleLocationError);
                } else {
                    // geolocation permission denied
                    this.permissionDenied = true;
                }
                });
            } else {
                // geolocation is not available
                this.permissionDenied = true;
            }
        } catch (error) {
          // User has denied camera access
          this.permissionDenied = true;
        }
      },
      obtainLocation(position) {
        console.log(position);
        },
        handleLocationError() {
        // handle geolocation error or permission denied
        this.permissionDenied = true;
        }
    },
    created:function(){
        if ("geolocation" in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then(permission => {
          if (permission.state === 'granted') {
            this.$parent.locationStatus = permission.state;
          } else {
            if(permission.state == "denied")
                this.permissionDenied = false
          }
        });
      } else {
                this.permissionDenied = false
      }
    }
  };
  </script>
  