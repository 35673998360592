<template>
  <div>
    <v-toolbar
      :color="primaryColor"
      dark
      >
      <v-btn  icon @click="$router.go(-1)">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
      <v-toolbar-title class="flex text-center">{{ $session.get("company_name")  }}</v-toolbar-title>
      <v-app-bar-nav-icon style="visibility:hidden"></v-app-bar-nav-icon>
      
    </v-toolbar>
    <v-divider></v-divider>
    <v-card :color="primaryColor" class="white--text pa-1 rounded-0 subtitle-2" align="center">
          Shift Detail
       </v-card>
       <v-container class="pa-0">
          <v-col  align="center">
            <h4 class="font-weight-bold mt-2 " :color="primaryColor" style="color:#374767">{{ item.site_name }}</h4>
            <h4 class="font-weight-bold mt-2 caption" :color="primaryColor" style="color:#374767">{{ item.time_in}} - {{ item.time_out}}<font v-if="Object.keys(item).length > 0 && (item.book_on!='' && item.book_off!='')"> (Completed)</font></h4>
            <h4 class="font-weight-regular mt-8" >{{ item.address}}</h4>
            
            <v-card
              class="d-flex justify-space-between mb-6"
              flat
              tile
              style="margin-top:200px"
            >
            <v-card @click="bookOn" flat tile v-if="item.book_on == '' && item.book_off == ''" >
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/bookon.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>
              <v-card  v-else flat tile  >
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/bookonlight.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>

              <v-card @click="checkCall" flat tile v-if="item.book_on != '' && item.check_call_status!=0 && item.book_off == ''">
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/checkin.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>
              <v-card v-else flat tile >
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/checkinlight.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>
              <v-card @click="bookOff" flat tile v-if="item.book_on != '' && item.book_off == ''">
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/bookoff.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>
              <v-card v-else flat tile >
                <v-img
                  alt="Book on"
                  class="shrink ma-8"
                  contain
                  :src="baseUrl+'img/bookofflight.png'"
                  transition="scale-transition"
                  width="50"
                />
              </v-card>
            </v-card>

            <v-row>
              
            </v-row>
          </v-col>
       </v-container>
  </div>

</template>
  
  <script>
  
  export default {
    name: 'ShiftDetail',
     data() {
    return {
      shiftId:0,
      item:{}
    }
  },
  created: function() {
    if(this.restrictedArea()){
      var params = this.$route.params;
      if(params.shiftId == undefined || this.$route.params.shiftId <100 )
      {
        this.$router.push({name: 'dashboard'});
      }
       else
       {
        this.shiftId = this.$route.params.shiftId;
          this.getData();
       }
      }
    },
    methods:{
      bookOn(){
        this.$getLocation({
          enableHighAccuracy: true, //defaults to false
      })
        .then(coordinates => {
          this.$root.latitude = coordinates.lat;
          this.$root.longitude = coordinates.lng;
          var params=new FormData();
          params.append("shift_id",this.shiftId);
          params.append("long",this.$root.latitude);
          params.append("lat",this.$root.longitude);
          this.$api.post("bookon",params).then((response)=>{
                  var data = response['data'];
                  if(data.book_on == 0)
                    this.$root.vtoast.show({message: data['message'],color:'red',icon:'mdi-close'})
                  else
                    this.$root.vtoast.show({message: data['message']})
           this.getData();         
          });
        });
      },
      bookOff(){
        this.$getLocation({
          enableHighAccuracy: true, //defaults to false
      })
        .then(coordinates => {
          this.$root.latitude = coordinates.lat;
          this.$root.longitude = coordinates.lng;
          var params=new FormData();
          params.append("shift_id",this.shiftId);
          params.append("long",this.$root.latitude);
          params.append("lat",this.$root.longitude);
          this.$api.post("bookoff",params).then((response)=>{
                  var data = response['data'];
                  if(data.book_off == 0)
                   this.$root.vtoast.show({message: data['message'],color:'red',icon:'mdi-close'})
                  else
                    this.$root.vtoast.show({message: data['message']})
           this.getData();         
          });
        });
      },
      checkCall(){
        this.$getLocation({
          enableHighAccuracy: true, //defaults to false
      })
        .then(coordinates => {
          this.$root.latitude = coordinates.lat;
          this.$root.longitude = coordinates.lng;
          var params=new FormData();
          params.append("shift_id",this.shiftId);
          params.append("long",this.$root.latitude);
          params.append("lat",this.$root.longitude);
          this.$api.post("checkCall",params).then((response)=>{
                  var data = response['data'];
                  if(data.checkin == 0)
                    this.$root.vtoast.show({message: data['message'],color:'red',icon:'mdi-close'})
                  else
                    this.$root.vtoast.show({message: data['message']})
           this.getData();         
          });
        });
      },
      getData:function(){
        var params=new FormData();
        params.append("shift_id",this.shiftId);
        this.$api.post("shiftDetail",params).then((response)=>{
                this.item = response['data']['shift_detail'];
            });
      }
    }

}
  </script>
  