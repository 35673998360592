<template>
      <v-card elevation="0" class="full-height justify-center text-center"  height="100%">
        <v-card-title class="justify-center" >Camera Access Required</v-card-title>
        <v-spacer></v-spacer>
        <v-icon size="64">mdi-camera</v-icon>
        <v-card-text class="justify-center">
          This application needs access to your camera to function properly.
          <br>
          <span v-if="cameraPermissionDenied" class="red--text mt-8">Camera permission has been denied. Please enable camera permission in your browser settings.</span>
          <br>
          <v-spacer></v-spacer>
         <span  class="green--text justify-center mt-8">Please reload page if you have already granted camera permission.</span>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-center">
          <v-btn :color="primaryColor" class="white--text mt-8" @click="requestCameraAccess" block>Allow Camera Access</v-btn>

        </v-card-actions>
      </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        cameraPermissionDenied: false,
      };
    },
    methods: {
      async requestCameraAccess() {
        try {
          await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          // User has granted camera access
        } catch (error) {
          // User has denied camera access
          this.cameraPermissionDenied = true;
        }
      },
    },
    created:function(){
        navigator.permissions.query({ name: "camera" }).then(res => {
            this.$parent.cameraStatus = res.state;
            if(res.state == "denied")
                this.cameraPermissionDenied = false
        });
    }
  };
  </script>
  