<template>
  <div>
    <v-toolbar
      :color="primaryColor"
      dark
      >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="flex text-center">{{ $session.get("company_name")  }}</v-toolbar-title>
      <v-app-bar-nav-icon style="visibility:hidden"></v-app-bar-nav-icon>
      
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      :color="primaryColor"
    >
    <template v-slot:prepend>
        <v-list-item two-line class="pt-4 pb-4">
          <v-list-item-avatar class="black--text" color="white">
            {{ capitalizeFirstLetter($session.get('officer_name'))[0] }}
          </v-list-item-avatar>

          <v-list-item-content class="white--text">
            <v-list-item-title>{{capitalizeFirstLetter($session.get('officer_name'))  }}</v-list-item-title>
            <v-list-item-subtitle class="white--text">Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list
        nav
        dense 
      >
        <v-list-item-group
          active-class="active-menu"
          v-model="menu"
        >
          <v-list-item>
            <v-list-item-title class="white--text">Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="white--text" @click="patrol">Patrol</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="white--text" @click="$root.logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-divider></v-divider>
    <v-card :color="primaryColor" class="white--text pa-1 rounded-0 subtitle-2" align="center">
          Dashboard 
       </v-card>
       <div>
        <div align="center" class="mt-16" v-if="items.length == 0">
          <h4  class="body-1">No shifts available</h4>
        </div>
        <v-list v-else>
          <v-list-item-group>
            <template v-for="(item,index) in items">
              <div :key="index">
                <v-list-item  @click="select(item)">
                <v-list-item-content>
                    <v-list-item-subtitle >
                      <font class="caption" color="grey">{{dateFormatterNew(item.shift_date)}}</font>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="font-weight-bold subtitle-1" style="color:#374767">{{item.site_name}}</v-list-item-subtitle>
                    <v-list-item-subtitle class="caption" >{{item.time_in}} - {{item.time_out}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
                <v-divider></v-divider>
              </div>
              </template>
          </v-list-item-group>
         </v-list>
          
        </div>
        
  </div>

</template>
<style scoped>
.active-menu{
  background-color: v-bind(secondaryColor);
}
</style>
  
  <script>
  
  export default {
    name: 'StaffDashboard',
     data() {
    return {
      userId:0,
      drawer:false,
      menu:0,
      items:[]
    }
  },
  created: function() {
        if(this.restrictedArea()){
          this.userId = this.$session.get("officer_id")
          this.getData();
      }
    },
    methods:{
      select(item){
        this.$router.push({name: 'detail', params : { shiftId: item.id}});
      },
      patrol(){
        this.$router.push({name: 'patrol'});
      },
      getData:function(){
        var params=new FormData();
        params.append("officer_id",this.userId);
        this.$api.post("allShift",params).then((response)=>{
                this.items = response['data']['all_shift'];
            });
      }
    }

}
  </script>
  